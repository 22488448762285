// Função que será chamada ao clicar no botão
function abrirPaginas(urlWhatsApp) {
    if (!urlWhatsApp) {
        console.error('A URL do WhatsApp não foi fornecida.');
        return;
    }

    // Abre a URL dinâmica do WhatsApp em uma nova aba
    window.open(urlWhatsApp, '_blank');

    // Abre a página franqueadoFeedback.js na mesma aba
    window.location.href = './FranqueadoFeedback';
    //window.open('./FranqueadoFeedback', '_blank').focus();    
}

// Exporta a função para que possa ser usada em outros arquivos
export { abrirPaginas };
