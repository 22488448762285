import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Index from './Pages/Index/index';
import Home from './Pages/Home';
import Sobre from './Pages/Home/sobre';
import Calendario from './Pages/Home/calendario';
import Franqueado from './Pages/Home/franqueado';
import FranqueadoFeedback from './Pages/Home/franqueadoFeedback.js';
import Vacinas from './Pages/Home/vacinas';
import Login from './Pages/Login/login';

import Menu from './Pages/Menu';
import CadFiliais from './Pages/CadFiliais';
import CadProdutos from './Pages/CadProdutos';
import CadVideos from './Pages/CadVideos';
import CadCargos from './Pages/CadCargosMenus';
import CadUsuarios from './Pages/CadUsuarios';
import Videos from './Pages/Videos';
import EstoquePedido from './Pages/CadEstoquePedido';
import CadAtivos from './Pages/CadAtivos';
import LancAtivos from './Pages/LancAtivos';

import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CarrinhoContext';
import { ListProvider } from './context/ListContext.jsx';
import { useAuthContext } from './context/AuthContext.jsx';

import ScrollToTop from './components/import/ScrollToTop';

const routeNames = {
  index: '/',
  home: '/Home',
  sobre: '/Sobre',
  calendario: '/Calendario',
  franqueado: '/Franqueado',
  franqueadoFeedback: '/FranqueadoFeedback',
  vacinas: '/Vacinas',
  login: '/Login',
  menu: '/Menu',
  cadFiliais: '/CadFiliais',
  cadProdutos: '/CadProdutos',
  cadVideos: '/CadVideos',
  cadCargos: '/CadCargos',
  cadUsuarios: '/CadUsuarios',
  videos: '/Videos',
  pedidosEstoque: '/PedidosEstoque',
  cadAtivos: '/CadAtivos',
  lancAtivos: '/LancAtivos',
};

const PrivateRouteUser = ({ Item, nomeRota }) => {
  const { isAuthenticated, hasPermissionMenu } = useAuthContext();
  console.log('Está Logado? ', isAuthenticated);

  const hasPermission = hasPermissionMenu(nomeRota);
  console.log('isAuthentic: ', isAuthenticated);
  console.log('tem permissao?', hasPermission);

  return isAuthenticated && hasPermission ? <Item /> : <Login />;
};

const Rotas = () => {
  return (
    <AuthProvider>
      <CartProvider>
        <ListProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path={routeNames.index} element={<Index />} />
              <Route path={routeNames.home} element={<Home />} />
              <Route path={routeNames.sobre} element={<Sobre />} />
              <Route path={routeNames.calendario} element={<Calendario />} />
              <Route path={routeNames.franqueado} element={<Franqueado />} />
              <Route path={routeNames.franqueadoFeedback} element={<FranqueadoFeedback />} />
              <Route path={routeNames.vacinas} element={<Vacinas />} />
              <Route path={routeNames.login} element={<Login />} />
              <Route
                path={routeNames.menu}
                element={<PrivateRouteUser Item={Menu} nomeRota="menu" />}
              />
              <Route
                path={routeNames.cadFiliais}
                element={
                  <PrivateRouteUser Item={CadFiliais} nomeRota="cadFiliais" />
                }
              />
              <Route
                path={routeNames.cadProdutos}
                element={
                  <PrivateRouteUser Item={CadProdutos} nomeRota="cadProdutos" />
                }
              />
              <Route
                path={routeNames.cadVideos}
                element={
                  <PrivateRouteUser Item={CadVideos} nomeRota="cadVideos" />
                }
              />
              <Route
                path={routeNames.cadCargos}
                element={
                  <PrivateRouteUser Item={CadCargos} nomeRota="cadCargos" />
                }
              />
              <Route
                path={routeNames.cadUsuarios}
                element={
                  <PrivateRouteUser Item={CadUsuarios} nomeRota="cadUsuarios" />
                }
              />
              <Route
                path={routeNames.videos}
                element={<PrivateRouteUser Item={Videos} nomeRota="videos" />}
              />
              <Route
                path={routeNames.cadAtivos}
                element={<PrivateRouteUser Item={CadAtivos} nomeRota="cadAtivos" />}
              />
              <Route
                path={routeNames.lancAtivos}
                element={<PrivateRouteUser Item={LancAtivos} nomeRota="lancAtivos" />}
              />
              <Route
                path={routeNames.pedidosEstoque}
                element={
                  <PrivateRouteUser
                    Item={EstoquePedido}
                    nomeRota="pedidosEstoque"
                  />
                }
              />
              <Route path="*" element={<Index />} />
            </Routes>
          </BrowserRouter>
        </ListProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default Rotas;
