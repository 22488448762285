import React, { useState } from 'react';
import HeaderSite from '../../components/layout/HeaderSite.js';
import Unidades from '../../components/layout/Unidades.js';
import Contact from '../../components/layout/Contact.js';
import Footer from '../../components/layout/Footer.js';
import GoToTop from '../../components/layout/GoToTop.js';
import sweetAlert from '../../components/layout/sweetAlert.js';
import '../../main.css';
import './franqueado.css';

import img1 from '../../imagens/milhao.jpg';
import img2 from '../../imagens/lucro.jpg';
import img3 from '../../imagens/investimento.jpg';

import CardIcon1 from '../../imagens/img/fotos/icons/handholdingdollar.png';

import { BiDollarCircle } from 'react-icons/bi';
import { FaHandHoldingDollar } from 'react-icons/fa6';
import { LuCalculator } from 'react-icons/lu';

import { abrirPaginas } from '../../components/layout/abrirPaginasFranqueado';

//import formatadores from "../../components/import/formatadores";

//import videoYouTube from 'https://www.youtube.com/embed/2ak-JjRkZy8?modestbranding=1'

//APRESENTAÇÃO SLIDES
//--------------------
//import apresentacaoPDF from "../../uploads/Previmune_Apresentacao.pdf#toolbar=0&navpanes=0&scrollbar=0&statusbar=1";
//import apresentacaoPDF from '../../uploads/Previmune_Apresentacao1.pdf';
import apresentacaoPDF from '../../uploads/PREVIMUNE_FRANQUIA_APRESENTACAO_2024.pdf';

import 'bootstrap/dist/css/bootstrap.css';
// import Carousel from 'react-bootstrap/Carousel';
// import carr1 from '../../uploads/apresentacao/Previmune_Apresentacao11.jpg';
// import carr2 from '../../uploads/apresentacao/Previmune_Apresentacao12.jpg';
// import carr3 from '../../uploads/apresentacao/Previmune_Apresentacao13.jpg';
// import carr4 from '../../uploads/apresentacao/Previmune_Apresentacao14.jpg';
// import carr5 from '../../uploads/apresentacao/Previmune_Apresentacao15.jpg';
// import carr6 from '../../uploads/apresentacao/Previmune_Apresentacao16.jpg';
// import carr7 from '../../uploads/apresentacao/Previmune_Apresentacao17.jpg';
// import carr8 from '../../uploads/apresentacao/Previmune_Apresentacao18.jpg';
// import carr9 from '../../uploads/apresentacao/Previmune_Apresentacao19.jpg';
// import carr10 from '../../uploads/apresentacao/Previmune_Apresentacao110.jpg';
// import carr11 from '../../uploads/apresentacao/Previmune_Apresentacao111.jpg';
// import carr12 from '../../uploads/apresentacao/Previmune_Apresentacao112.jpg';
// import carr13 from '../../uploads/apresentacao/Previmune_Apresentacao113.jpg';
// import carr14 from '../../uploads/apresentacao/Previmune_Apresentacao114.jpg';
// import carr15 from '../../uploads/apresentacao/Previmune_Apresentacao115.jpg';
// import carr16 from '../../uploads/apresentacao/Previmune_Apresentacao116.jpg';

function qntMinimaCaracteres(str) {
  if (str === 'CPF')
    if (str.length < '14') {
      document.getElementById('helpCpf').style = 'display: block;';
      return false;
    } else {
      document.getElementById('helpCpf').style = 'display: none;';
      return true;
    }
  else return true;
}

const Franqueado = () => {
  const [prazoImplementacao, setPrazoImplementacao] = useState('');
  const [valorDisponivelInvest, setValorDisponivelInvest] = useState('');

  const handleSelectChangePrazo = (event) => {
    event.target.style.color = 'black';
    const prazoSelecionado = event.target.value;
    if (!!prazoSelecionado && prazoSelecionado !== '-1')
      setPrazoImplementacao(prazoSelecionado);
  };
  const handleSelectChangeValor = (event) => {
    event.target.style.color = 'black';
    const valorDisponivelInvest = event.target.value;
    if (!!valorDisponivelInvest && valorDisponivelInvest !== '-1')
      setValorDisponivelInvest(valorDisponivelInvest);
  };

  const verificacoes = () => {
    return (
      prazoImplementacao &&
      prazoImplementacao !== '-1' &&
      valorDisponivelInvest &&
      valorDisponivelInvest !== '-1'
    );
  };

  const redirecionarParaFormulario = () => {
    window.open(
      'https://vendas.getcrm.com.br/Contacts/Contact.aspx?id=85923bf5-c4b7-42c1-95d2-2cee1ba43db1',
      '_blank'
    );
  };

  function gotoWhatsapp() {
    if (!verificacoes()) {
      sweetAlert(
        'warning',
        'Atenção',
        'Preencha corretamente as informações adicionais!'
      );
      return;
    }

    //let number = localStorage.getItem("whats");
    //Numero de Whatsapp para contato: Tela do Franqueado
    let number = '+5535988494588'; //Sandro
    //let number = '+5535991721004'; //Hugo

    let nome = document.getElementById('txtnome').value;
    let profissao = document.getElementById('txtProfissao').value;
    let cidade = document.getElementById('txtCidade').value;
    let uf = document.getElementById('txtUf').value;
    let celular = document.getElementById('txtCel').value;
    let telefone = document.getElementById('txtTel').value;
    let email = document.getElementById('txtEmail').value;
    let prazoParaImplementacao = prazoImplementacao;
    let valorParaInvestimento = valorDisponivelInvest;

    let url =
      `https://api.whatsapp.com/send?phone=` +
      number +
      `&text=
            FORMULÁRIO DE FRANQUEADO: %0a%0a
            DADOS PESSOAIS%0a
            Nome: ` +
      nome +
      `%0a

            Profissão: ` +
      profissao +
      `%0a
            ------------------------------%0a
            ENDEREÇO%0a
            
            Cidade: ` +
      cidade +
      `%0a
            UF: ` +
      uf +
      `%0a
            ------------------------------%0a
            CONTATO%0a
            Celular: ` +
      celular +
      `%0a
            Telefone: ` +
      telefone +
      `%0a
            Email: ` +
      email +
      `%0a
            ------------------------------%0a
            INFORMAÇÕES ADICIONAIS%0a
            Prazo para implementação: ` +
      prazoParaImplementacao +
      `%0a
            Valor disponível para investimento: ` +
      valorParaInvestimento +
      `%0a
            ;`;

    //window.open(url, '_blank').focus();
    abrirPaginas(url);
  }

  return (
    <>
      <HeaderSite />
      <section id="formulario" className="section">
        <div className="container">
          <div>
            <div className="section-header">
              <h2
                className="section-title wow fadeIn"
                data-wow-duration="1000ms"
                data-wow-delay="0.3s"
              >
                Conheça a melhor franquia de vacinas do país:
              </h2>
              <hr className="lines wow zoomIn" data-wow-delay="0.3s" />
            </div>

            <br />
            <div className="videoWrapper">
              {/* <iframe
                  id="ytplayer"
                  type="text/html"
                  // width="720"
                  // height="405"
                  title="Apresentação"
                  src="https://www.youtube.com/embed/2ak-JjRkZy8?modestbranding=1"
                  frameBorder="0"
                  allowFullScreen
                ></iframe> */}
              <iframe
                id="ytplayer"
                type="text/html"
                //width="914"
                //height="514"
                src="https://www.youtube.com/embed/par6sibeuTE?si=QsDBfItNdVYGIBiw"
                title="Seja um franqueado Previmune. A melhor franquia do Brasil."
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div>
              <div
                className="row row-eq-height justify-content-center"
                style={{ textAlign: 'center' }}
              >
                <div
                  className="col-lg-4 mb-4"
                  style={{ backgroundColor: '#e9e9e9' }}
                >
                  <br />
                  <div
                    className="card wow bounceInUp"
                    style={{ backgroundColor: '#e9e9e9' }}
                  >
                    {/* <img src={img1} alt="unidadePassos"></img> */}
                    <div className="card-body">
                      <div className="card-icon">
                        <BiDollarCircle />
                      </div>
                      <h5 className="card-title" style={{ fontSize: '1.3em' }}>
                        <b>Fature até mais de R$ 1 Milhão de Reais por ano</b>
                      </h5>
                      {/* <p className="card-text">Com a sua franquia de sucesso</p> */}
                      <br />
                    </div>
                  </div>
                  <br />
                </div>

                <div className="col-lg-4 mb-4">
                  <br />
                  <div className="card wow bounceInUp">
                    {/* <img src={img3} alt="unidadePassos"></img> */}
                    <div className="card-body">
                      <div className="card-icon">
                        <LuCalculator />
                      </div>
                      <h5 className="card-title" style={{ fontSize: '1.3em' }}>
                        <b>INVESTIMENTO </b>
                      </h5>
                      <br />
                      <p className="card-text">A partir de R$ 80 mil. </p>
                    </div>
                    <br />
                  </div>
                </div>

                <div
                  className="col-lg-4 mb-4"
                  style={{ backgroundColor: '#e9e9e9' }}
                >
                  <br />
                  <div
                    className="card wow bounceInUp"
                    style={{ backgroundColor: '#e9e9e9' }}
                  >
                    {/* <img src={img2} alt="unidadePassos"></img> */}
                    <div className="card-body">
                      <div className="card-icon">
                        <img src={CardIcon1} alt=''></img>
                      </div>
                      <h5 className="card-title" style={{ fontSize: '1.3em' }}>
                        <b>Média de Lucratividade entre 15% e 30%</b>
                      </h5>
                      {/* <p className="card-text">Lucratividade alta e estruturada</p> */}
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            {/*
            ///////// APRESENTAÇÃO
            <div
              className="container carousel-container"
              // style={{ display: 'block', width: 700, padding: 30 }}
              align="center"
            >
              <Carousel fade>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr1} alt="One" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr2} alt="Two" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr3} alt="Three" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr4} alt="Four" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr5} alt="Five" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr6} alt="Six" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr7} alt="Seven" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr8} alt="Eight" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr9} alt="Nine" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr10} alt="Ten" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr11} alt="Eleven" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr12} alt="Twelve" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr13} alt="Thirteen" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr14} alt="Fourteen" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr15} alt="Fourteen" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={carr16} alt="Fourteen" />
                </Carousel.Item>
              </Carousel>
              </div>*/}

            {/* //PDF */}
            {/*
            <div className="container" align="center">
              <embed
                src={apresentacaoPDF}
                width="720"
                height="450"
                type="application/pdf"
                style={{ borderRadius: "10px" }}
              />
            </div>
            ///////////////////////
            */}

            <div>
              <div
                className="form-group btn-franqueado"
                style={{ paddingTop: '80px' }}
                align="center"
              >
                <h2 style={{ fontSize: '1.8em' }}>
                  Quer ver a nossa apresentação completa?
                </h2>
                <a
                  href={apresentacaoPDF}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#e76114',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                >
                  <b>CLIQUE AQUI</b>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div style={{ paddingTop: '80px' }}>
            <div className="section-header">
              <h2
                className="section-title wow fadeIn"
                data-wow-duration="1000ms"
                data-wow-delay="0.3s"
              >
                Formulário Cadastral para Franqueados
              </h2>

              <hr className="lines wow zoomIn" data-wow-delay="0.3s" />

              <p
                className="section-subtitle wow fadeIn"
                data-wow-duration="1000ms"
                data-wow-delay="0.3s"
              >
                Preencha o formulário abaixo e conheça melhor nosso modelo de
                franquias.
              </p>
            </div>
            <form>
              <br />
              <br />
              <h3 style={{ width: '100%', textAlign: 'center' }}>
                Dados Pessoais
              </h3>
              <div
                style={{
                  backgroundColor: 'rgb(243, 242, 242)',
                  padding: '20px',
                  borderRadius: '20px',
                  marginLeft: '3%',
                  marginRight: '3%',
                }}
              >
                <div className="form-group">
                  <label htmlFor="txtnome">Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtnome"
                    aria-describedby="emailHelp"
                    placeholder="nome completo"
                  />
                  <small
                    id="helpNome"
                    className="form-text text-muted"
                    style={{ display: 'none' }}
                  >
                    <p className="text-danger">**Digite o nome completo</p>
                  </small>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="txtProfissao">Profissão</label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtProfissao"
                        placeholder="profissão"
                      />
                    </div>
                  </div>
                </div>{' '}
              </div>

              <br />
              <h3 style={{ width: '100%', textAlign: 'center' }}>Endereço</h3>
              <div
                style={{
                  backgroundColor: 'rgb(243, 242, 242)',
                  padding: '20px',
                  borderRadius: '20px',
                  marginLeft: '3%',
                  marginRight: '3%',
                }}
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="txtCidade">Cidade</label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtCidade"
                        placeholder="nome da cidade"
                      />
                      <small
                        id="helpCidade"
                        className="form-text text-muted"
                        style={{ display: 'none' }}
                      >
                        <p className="text-danger">**cidade</p>
                      </small>
                    </div>
                    <div className="col-6">
                      <label htmlFor="txtUf">UF</label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtUf"
                        placeholder="uf"
                      />
                      <small
                        id="helpUf"
                        className="d-none form-text text-muted"
                      >
                        <p className="text-danger">**uf</p>
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <h3 style={{ width: '100%', textAlign: 'center' }}>Contato</h3>
              <div
                style={{
                  backgroundColor: 'rgb(243, 242, 242)',
                  padding: '20px',
                  borderRadius: '20px',
                  marginLeft: '3%',
                  marginRight: '3%',
                }}
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="txtCel">Celular</label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="15"
                        id="txtCel"
                        placeholder="(00) 00000-0000"
                      />
                      {/*onKeyUp={formataCelular(this,event)} /> */}
                      <small
                        id="helpCel"
                        className="form-text text-muted"
                        style={{ display: 'none' }}
                      >
                        <p className="text-danger">**numero do celular</p>
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="txtTel">Telefone</label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="14"
                        id="txtTel"
                        placeholder="(00) 00000-0000"
                      />{' '}
                      {/*onKeyUp="formataTelefone(this,event);" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="txtEmail">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtEmail"
                        placeholder="email"
                      />
                      <small
                        id="helpEmail"
                        className="form-text text-muted"
                        style={{ display: 'none' }}
                      >
                        <p className="text-danger">**email</p>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h3 style={{ width: '100%', textAlign: 'center' }}>
                Informações Adicionais
              </h3>
              <div
                style={{
                  backgroundColor: 'rgb(243, 242, 242)',
                  padding: '20px',
                  borderRadius: '20px',
                  marginLeft: '3%',
                  marginRight: '3%',
                }}
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="txtPrazo" style={{ height: '70px' }}>
                        Prazo para implementação
                      </label>

                      <select
                        onChange={(e) => handleSelectChangePrazo(e)}
                        style={{
                          borderRadius: '5px',
                          width: '100%',
                          height: '30px',
                          padding: '2px',
                          color: 'gray',
                        }}
                        id="txtPrazo"
                        value={prazoImplementacao}
                      >
                        <option key="-1" value="-1">
                          -- SELECIONE UMA OPÇÃO --
                        </option>
                        <option key="1" value="3 meses">
                          3 meses
                        </option>
                        <option key="2" value="6 meses">
                          6 meses
                        </option>
                        <option key="3" value="12 meses">
                          12 meses
                        </option>
                      </select>
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="txtInvestimento"
                        style={{ height: '70px' }}
                      >
                        Valor disponível para investimento
                      </label>

                      <select
                        onChange={(e) => handleSelectChangeValor(e)}
                        style={{
                          borderRadius: '5px',
                          width: '100%',
                          height: '30px',
                          padding: '2px',
                          color: 'gray',
                        }}
                        id="txtInvestimento"
                        value={valorDisponivelInvest}
                      >
                        <option key="-1" value="-1">
                          -- SELECIONE UMA OPÇÃO --
                        </option>
                        <option key="1" value="Até 100 mil">
                          Até 100 mil
                        </option>
                        <option key="2" value="De 100 a 150 mil">
                          De 100 a 150 mil
                        </option>
                        <option key="3" value="Acima de 150 mil">
                          Acima de 150 mil
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="form-group btn-franqueado" align="center">
                <button
                  type="button"
                  className="btn btn-common"
                  //onClick={() => { window.open('./FranqueadoFeedback').focus(); }}
                  onClick={() => gotoWhatsapp()}
                >
                  Enviar Proposta
                </button>
              </div>
            </form>
          </div>

          <div
            className="form-group btn-franqueado"
            align="center"
            style={{ textAlign: 'center', padding: '10px' }}
          >
            {/*
            <button
              type="button"
              className="btn btn-common"
              onClick={redirecionarParaFormulario}
            >
              Preencher Formulário
            </button>
            */}

            {/*
            //////// IFRAME SISTEMA COMERCIAL
            <iframe
              id="ifrForm"
              src="https://vendas.getcrm.com.br/Contacts/Contact.aspx?id=85923bf5-c4b7-42c1-95d2-2cee1ba43db1"
              width="640"
              height="1100"
              align="middle"
            ></iframe>
            */}
          </div>
        </div>
      </section>

      <Unidades />
      <Contact />
      <Footer />
      <GoToTop />
    </>
  );
};

export default Franqueado;
