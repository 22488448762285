import React from 'react';
import HeaderSite from '../../components/layout/HeaderSite.js';
import Footer from '../../components/layout/Footer.js';
import '../../main.css';
import './franqueadoFeedback.css';

import logoBranco from "../../imagens/img/logos/logo_branco.png";
import hands from "../../imagens/img/fotos/icons/hands.png";
import logoBackground from "../../imagens/img/logos/favicon.png"

import 'bootstrap/dist/css/bootstrap.css';

const FranqueadoFeedback = () => {

  return (
    <>
      <HeaderSite />

      <section id="feedback" className="section">
        <div className="container">
          <div className='areaFeedback'>
            <img src={logoBackground} alt='' className='imgLogoBackground'></img>
            <div className="section-header">
              <h2
                className="section-title-feedback wow fadeIn"
                data-wow-duration="1000ms"
                data-wow-delay="0.3s"
              >
                Obrigado pelo seu interesse !!
              </h2>
              <hr className="lines wow zoomIn" data-wow-delay="0.3s" />
            </div>
            <img src={hands} alt='' className='imgHands'></img>

            <br />

            <div>
              <div
                className='textFeedback'
                align="center"
              >
                <h2 style={{ fontSize: '1.8em', color: '#d8d5d5', zIndex: '2' }}>
                  Confirme o envio dos seus dados para nosso contato de Whatsapp<br />e em breve nossa equipe comercial entrará em contato com você.
                </h2>

              </div>
            </div>
            <img src={logoBranco} alt='' className='imgLogo'></img>
          </div>
          <br />
        </div>
      </section>


      <Footer />
    </>
  );
};

export default FranqueadoFeedback;
