import React, { useState, useEffect } from "react";
import "../../../main.css";
import "./CartaoVacina.css";
import formatCurrency from "../../import/formatCurrency";

import { useCartContext } from "../../../context/CarrinhoContext.jsx";

import buscaDadosApi  from "../../../api/buscaDadosApi";
import {consultaApi} from "../../../api/consultaApi";
import BuscaProdutosApi from "../../../api/buscaProdutosApi";

import imagemPadrao from '../../../imagens/padrao_produto.jpg';

// import { useCartContext } from "../../../context/CarrinhoContext";

function CartaoVacina({data}) {
        
  const {cartItems, setCartItems} = useCartContext();
      
  const [vacinas, setVacinas] = useState([]);  
      
  const handleAddCart = (vacinaItem) => {
    if (vacinaItem.quantidade > 0) {
      const isItemAlreadyAdded = cartItems.some(cartItem => cartItem.IDprodutos === vacinaItem.IDprodutos);      
      if (!isItemAlreadyAdded) setCartItems([...cartItems, vacinaItem]);
    }
  }
  
  useEffect(() => {            
    carregaProdutos();    
  }, []);

  async function carregaProdutos() {
    const idFilial = localStorage.getItem('filial');
    const response = await BuscaProdutosApi(idFilial);

    response.forEach(produto => {
      produto.quantidade = 0;
    })

    setVacinas(response);    
  }
  
  const Decrement = (item) => {
    if (item.quantidade > 0) {
      setVacinas((current) => {
        const result = current.map((vacina, key) => {
          if (vacina.IDprodutos === item.IDprodutos) {
            return { ...vacina, ...key, quantidade: item.quantidade - 1 };
          }

          return vacina;
        });
        return result;
      });
    }
  };
  
  const Increment = (item) => {
    if (item.quantidade < 99) {
      setVacinas((current) => {
        const result = current.map((vacina, key) => {
          if (vacina.IDprodutos === item.IDprodutos) {
            return { ...vacina, ...key, quantidade: item.quantidade + 1 };
          }

          return vacina;
        });
        return result;
      });
    }
  };
  

  return (
    <>    
      {vacinas.map((vacina) => {
        let imagemURL = vacina.nomeImg 
        ? `/assets/images/produtos/${vacina.nomeImg}`
        : imagemPadrao;
         
        return (        
          <div
            key={vacina.IDprodutos}
            className="card"            
          >
            <div className="cartaoVacina">
              <img className="cartaoVacinaImage"
                alt="imagem do produto"
                src={imagemURL}
                style={{ width: "70%", borderRadius: "20px" }}
              />
              <h5 className="cartaoVacinaTitulo">{vacina.nome}</h5>
              <p className="cartaoVacinaDescricao">{vacina.descricao}</p>
              {vacina.valor === 0 
                ? (<p className="cartaoVacinaPreco"> Preço à consultar </p>) 
                : (<p className="cartaoVacinaPreco"> {formatCurrency(vacina.valor, 'BRL') }</p>)
              }              
                         
              <div className="card-item-quantidade-container">                
                <button type="button" className="card-item-button-incDecreQtd" onClick={() => Decrement(vacina)}>
                    -
                </button>
                <input type="text" className="card-item-inputQtd" value={vacina.quantidade} maxLength="99"></input>
                <button type="button" className="card-item-button-incDecreQtd" onClick={() => Increment(vacina)}>
                    +
                </button>
              </div>
              
              <button
                type="button"
                className="btn cartaoVacinaComprar"
                onClick={() => handleAddCart(vacina)}
              >
                Comprar
              </button>
              
              {/*</div>*/}
            </div>
          </div>
        
      )})}      
    </>
  );
}

export default CartaoVacina;
